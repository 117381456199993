export default [
  { key: "no", label: "ลำดับที่", sortable: true , thStyle:{color:"white",width: "5% !important"}},
  {
    key: "hwTypeName",
    label: "Hardware type",
    
    thStyle:{color:"white"}
  },  
  { key: "hwTypeStatus", label: "สถานะ", sortable: true , thStyle:{color:"white",width: "8% !important", textAlign: "center"}},  
  {
    key: "action",
    label: "จัดการ",
    sortable: false,
    thStyle: { width: "12% !important", textAlign: "center", color:"white" },
  },
];
